@font-face {
  font-family: 'beloved';
  src: url('../src/assets/fonts/beloved.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'beloved-sans';
  src: url('../src/assets/fonts/CenturyGothic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


* {
  border: 0;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family:'beloved-sans';
  background-color: #f4f4f4 !important;
}

a {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  text-decoration: none;
}

/* Navbar */
#navbar {
  background: white;
  color: rgb(13, 26, 38);
  position: fixed;
  top: 0;
  height: 70px;
  line-height: 60px;
  width: 100%;
  z-index: 10;
  display: flex;
  align-items: center;
}

.nav-wrapper {
  margin: auto;
  text-align: center;
  width: 80%;
}

@media(max-width: 992px) {
  .nav-wrapper {
    width: 100%;
  }
}



.logo {
  float: left;
  margin-left: 28px;
  font-size: 1.5em;
  height: 60px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

#navbar ul {
  display: inline-block;
  float: right;
  list-style: none;
  margin-top: -2px;
  text-align: right;
  transition: transform 0.5s ease-out;
}

@media(max-width: 992px) {
  #navbar ul {
    display: none;
  }
}

#navbar li {
  display: inline-block;
}

#navbar li a {
  color: rgb(13, 26, 38);
  display: block;
  font-size: 0.9vw;
  height: 60px;
  letter-spacing: 1px;
  margin: 0 20px;
  padding: 0 4px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.5s ease;
}

#navbar li a:hover {
  color: rgb(184 151 181);
  transition: all 1s ease;
}

/* Animated Bottom Line */
#navbar li a:before, #navbar li a:after {
  content: '';
  position: absolute;
  width: 0%;
  height: 1px;
  bottom: 10px;
  background: rgb(13, 26, 38);
}

#navbar li a:before {
  left: 0;
  transition: 0.5s;
}

#navbar li a:after {
  background: rgb(13, 26, 38);
  right: 0;
}

#navbar li a:hover:before {
  background: rgb(13, 26, 38);
  width: 100%;
  transition: width 0.5s cubic-bezier((0.22, 0.61, 0.36, 1));
}

#navbar li a:hover:after {
  background: transparent;
  width: 100%;
}

/* Mobile Menu Icon */
@media(max-width: 992px) {
  .menuIcon {
    cursor: pointer;
    display: block;
    position: fixed;
    right: 35px;
    top: 20px;
    height: 23px;
    width: 27px;
    z-index: 12;
  }

  .icon-bars {
    background: rgb(13, 26, 38);
    position: absolute;
    left: 1px;
    top: 45%;
    height: 2px;
    width: 20px;
    transition: 0.4s;
  }

  .icon-bars::before {
    background: rgb(13, 26, 38);
    content: '';
    position: absolute;
    left: 0;
    top: -8px;
    height: 2px;
    width: 20px;
    transition: 0.3s width 0.4s;
  }

  .icon-bars::after {
    background: rgb(13, 26, 38);
    content: '';
    position: absolute;
    left: 0;
    bottom: -8px;
    height: 2px;
    width: 20px;
    transition: 0.3s width 0.4s;
  }

  .icon-bars.overlay {
    background: rgb(13, 26, 38);
    width: 20px;
    /* animation: middleBar 3s infinite 0.5s; */
  }

  @keyframes middleBar {
    0% { width: 0px; }
    50% { width: 20px; }
    100% { width: 0px; }
  }

  .icon-bars.overlay::before {
    background: rgb(13, 26, 38);
    /* width: 10px; */
    /* animation: topBar 3s infinite 0s; */
  }

  @keyframes topBar {
    0% { width: 0px; }
    50% { width: 10px; }
    100% { width: 0px; }
  }

  .icon-bars.overlay::after {
    background:  rgb(13, 26, 38);
    /* width: 15px; */
    /* animation: bottomBar 3s infinite 1s; */
  }

  @keyframes bottomBar {
    0% { width: 0px; }
    50% { width: 15px; }
    100% { width: 0px; }
  }

  .menuIcon.toggle .icon-bars {
    background: transparent;
  }

  .menuIcon.toggle .icon-bars::before,
  .menuIcon.toggle .icon-bars::after {
    content: '';
    background: rgb(13, 26, 38);
    width: 20px;
    height: 1px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    transition: 0.4s;
  }

  .menuIcon.toggle .icon-bars::before {
    transform: rotate(45deg);
  }

  .menuIcon.toggle .icon-bars::after {
    transform: rotate(-45deg);
  }

  .menuIcon.toggle .icon-bars.overlay {
    background: transparent;
  }
}

/* Responsive Mobile Menu */
.overlay-menu {
  background: rgb(255, 255, 255);
  color: rgb(13, 26, 38);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  padding-right: 15px;
  transform: translateX(-100%);
  width: 100%;
  height: 100vh;
  transition: transform 0.2s ease-out;
  z-index: 11;
}

.overlay-menu.active {
  transform: translateX(0);
}

.overlay-menu ul, .overlay-menu li {
  display: block;
  position: relative;
}

.overlay-menu li a {
  display: block;
  font-size: 12px;
  letter-spacing: 4px;
  padding: 10px 0;
  text-align: center;
  text-transform: uppercase;
  transition: color 0.3s ease;
}

.overlay-menu li a:hover,
.overlay-menu li a:active {
  color: rgb(28, 121, 184);
  transition: color 0.3s ease;
}


.slick-slide div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
}

.slick-dots li button:before {
  color: #000;
}

.slider-body {
  width: 100%;
  margin: 60px auto;
  padding: 0px;
}

.slick-slide img{
  display: block;
  filter: opacity(0.5) sepia(10.5) hue-rotate(47deg) grayscale(1);
}

.slick-initialized .slick-slide {
  display: flex;
  justify-content: center;
}

.copyrigth-section {
  text-align: center;
  padding: 1rem 0rem;
  background: white;
}

.copyrigth-section p {
  margin-bottom: 0;
  opacity: .95;
  color: #0d1a26;
}

@media(max-width: 768px) {
  .slider-body {
    width: 95%;
    margin: 60px auto;
  }
  .slick-slide img{
    transform: scale(.75)
  }

  .copyrigth-section p {
    font-size: .75rem;
}
}

g.outer {
  transform: translate(0, -40.4813px);
}