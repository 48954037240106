.social-am {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}

.social-am a {
  padding: 10px;
  filter: invert(1);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  margin-right: 15px;
  text-align: center;
  border-radius: 100px;
}

.social-am a:hover {
  filter: invert(.75);
}

.social-am a img {
  width: 35px;
  cursor: pointer;
}

.social-am img:hover {
  filter: opacity(0.95);
}
  

  .image-am {
    height: 95%;
    margin-right: auto;
}
  
  .image-am img {
    height: 100%;
    width: 115%;
    object-fit: cover;
    filter: brightness(1.075) opacity(0.95);
  }

  
  @media(max-width: 1290px) {

    .image-am img {
      width: 95%;
    }

  }

  .right.contact {
    text-align: left;
    margin-left: -60px;
  }

  .left.contact {
    justify-content: center;
    background-color: #F0EBE5;
  }
  


  .lora-section {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fffaf5;
    height: 100vh;
    justify-content: flex-end;
  }

  .lora-section-contact {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fffaf5;
    height: 100vh;
    justify-content: flex-start;
  }
  
  .text-section {
      background-color: #F0EBE5;
      text-align: center;
      padding: 100px;
      margin: auto;
      position: relative;
      left: 5%;
      width: 45vw !important;
      margin-top: auto !important;
  }

  .text-section-contact {
    background-color: #7a8074;
    color: white;
    text-align: center;
    padding: 100px;
    margin: auto;
    position: relative;
    z-index: 1;
    right: 5%;
    width: 45vw !important;
    margin-top: auto !important;
}
  
.image-section {
  padding: 0 !important;
    background: #7A8074;
    height: 100vh;
}

.image-section img.img-fluid {
  max-width: 90%;
  height: 100%;
  object-fit: cover;
  object-position: left;
  width: 100%;
  overflow: hidden;
  -webkit-object-fit: cover;
}

.image-section-contact {
  padding: 0 !important;
    background: #F0EBE5;
    height: 100vh;
}

.image-section-contact img.img-fluid {
  max-width: 90%;
    height: 100%;
    position: relative;
    right: -10%;
    object-fit: cover;
    width: 100%;
    overflow: hidden;
    -webkit-object-fit: cover;
}
  
  .lora-title {
    font-size: 4rem;
    margin-bottom: 30px;
    font-family: 'beloved';
  }
  
  .lora-description {
    line-height: 1.8rem;
    font-size: .85rem;
    padding: 0 60px;
  }

  @media(max-width: 922px) {

    .lora-section {
      height: 100%;
    }

    .lora-section-contact {
      height: 100%;
    }

    .text-section {
        padding: 60px 10px !important;
        position: relative;
        width: 100% !important;
        left: 0;
    }

    .text-section-contact {
      padding: 60px 10px !important;
      position: relative;
      width: 100% !important;
      right: 0;
    }

    .lora-title {
      font-size: 3rem;
    }
    
    .image-section{
      padding: 0 !important;
      height: 100%;
    }

    .image-section-contact {
        height: 100%;
    }
  }