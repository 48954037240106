
    
    .image-am {
      height: 95%;
      margin-right: auto;
  }
    
    .image-am img {
      height: 100%;
      width: 115%;
      object-fit: cover;
      filter: brightness(1.075) opacity(0.95);
    }
  
    
    @media(max-width: 1290px) {
  
      .image-am img {
        width: 95%;
      }
  
    }
    
  
  
    .lora-section-service {
      align-items: center;
      justify-content: center;
      background: #fffaf5;
      height: 100vh;
    }
    
  
    .text-section-service {
        background-color: #fffaf5;
        color: black;
        text-align: left;
        padding: 40px 60px !important;
        height: 100vh;
    }
        
    .image-section-service {
        padding: 0 !important;
        background: #F0EBE5;
        height: 100vh;
    }
  
  .image-section-service img.img-fluid {
    max-width: 50%;
    height: 100%;
    position: relative;
    right: -10%;
    object-fit: cover;
    object-position: right;
  }
    
    .lora-title-service {
      font-size: 4rem;
      margin-bottom: 80px;
      font-family: 'beloved';
      text-align: center;
    }
    
    .lora-description-service {
      line-height: 1.5rem;
      font-size: .85rem;
      padding: 0px;
      margin-bottom: 0;
  }

    .lora-serv-section {
      display: flex;
      margin-bottom: 50px;
  }

    .lora-serv-section img {
      width: 50px;
      margin-right: 30px;
  }
  
    @media(max-width: 922px) {
  
        .lora-section-service {
            height: 100%;
        }
  
  
      .text-section {
          padding: 10px 20px;
          position: relative;
          width: 100% !important;
          left: 0;
      }
  
      .text-section-service {
        padding: 0px 40px !important;
        position: relative;
        width: 100% !important;
        right: 0;
        height: 100%;
      }

      .image-section-service {
        height: 50vh;
    }
  
      .lora-title {
        font-size: 3rem;
      }
      
      .image-section{
        padding: 0 !important;
      }

      .lora-description-service {
          font-size: .75rem;
      }
    }