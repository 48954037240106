.testimonial-section {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background: #cfd2c2;
  }
  
  .testi-user-img {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }
  
  .gallery-thumbs {
    display: flex;
    width: 75%;
    justify-content: space-evenly;
  }
  
  .inactive-thumbs {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 45%;
  }
  
  .active-thumb {
    width: 45%;
  }
  
  .thumb {
    width: 100%;
    transition: all 0.3s ease;
    object-fit: cover;
  }
  
  .thumb img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .thumb.inactive {
    filter: grayscale(100%);
    height: 43vh; /* Adjusted height for inactive items */
  }
  
  .thumb.active {
    filter: grayscale(0%);
    position: relative;
    height: 90vh; /* Larger height for the active item */
  }
  
  .user-saying {
    position: absolute;
    top: 0;
    left: 75%;
    width: 100%;
    height: 100%;
    background: var(--theme-color2);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    text-decoration: none !important;
  }


  
  .testimonial {
    height: 50vh;
    padding: 60px;
    background: #fffaf5ba;
  }

  .testimonial:hover {
    background: #f0ebe5;
  }
  
  .testimonial p {
    font-size: 1.75vw;
    color: black;
    font-weight: 100;
    margin: 20px 0 0 0;
    font-family: 'beloved-sans';
    text-align: center;
  }
  
  .testimonial .designation {
    color: rgb(50, 50, 50);
    font-size: 0.85rem;
    font-weight: 300;
    margin: 20px 0 0 0;
    line-height: 20px;
    text-align: center;
  }
  
  @media (max-width: 922px) {
    .testimonial-section {
        height: 90vh;
    }
    
    .gallery-thumbs {
        display: flex;
        width: 80%;
        justify-content: flex-start;
    }

    .thumb {
      transition: unset;
    }
    .thumb.active {
      transform: scale(1.2);
      display: block;
    }
  
    .testimonial p {
      font-size: 1em;
    }
  
    .testimonial {
      height: 90%;
      padding: 20px 10px;
    }
  
    .testimonial .designation {
      font-size: 0.65rem;
      font-weight: 300;
      margin: 10px 0 0 0;
      line-height: 20px;
      text-align: center;
    }

    .thumb.active {
        height: 50vh;
    }

    .active-thumb {
        width: 70%;
    }

    .inactive-thumbs{
        display: none;
    }

    .thumb.inactive {
        display: none;
    }


    .user-saying {
        left: 50%;
        padding: 0;
      }
  }
  