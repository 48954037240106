.home-section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-size: cover;
    background-position: center;
    position: relative;
    color: white;
    background: linear-gradient(to right, #7a8074 5%, rgba(255, 255, 255, 0) 5%), url('../images/backg.jpg') no-repeat center;
    background-size: cover;
}

.home-section::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, #7a8074 5%, rgba(255, 255, 255, 0) 5%), rgba(0, 0, 0, 0.5);
    z-index: 1;
}


.content {
    background-color: #f0ebe580;
    text-align: center;
    padding: 95px 60px;
    margin: auto;
    position: relative;
    z-index: 2;
    width: 45vw !important;
    margin-top: auto !important;
}

.content h1 {
    font-size: 5em;
    margin-bottom: 0.5em;
    margin-top: -0.5em;
    font-family: 'beloved';
}

.content p {
    font-size: 1.05vw;
    line-height: 1.65rem;

}

a.btn-home {
    background: transparent;
    border: 2px solid white;
    padding: 10px 40px;
    color: white;
    font-size: 1.05vw;
    font-weight: lighter;
    cursor: pointer;
    border-radius: 4px;
    text-transform: capitalize;
    position: relative;
    top: 30px;
    text-decoration:unset;
    font-weight: 500;
}

a.btn-home:hover {
    background: white;
    color: #1d1d1d;
}

@media(max-width: 992px) {
    .home-section {
        background: url('../images/backg.jpg') no-repeat center;
        background-size: cover;
    }
    
    .home-section::after {
        background: rgba(0, 0, 0, 0.5);
    }

    .content {
        max-width: 100%;
        padding: 60px 0px;
        width: 90% !important;
        margin-top: 125px !important;
    }

    .home-section {
        height: 120vh;
    }

    a.btn-home {
        font-size: 1rem;
    }

    
    .content p {
        font-size: .85rem;
        padding: 0px 20px;
    }

}
